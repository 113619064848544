import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import './Checkout.scss';
import { Alert, Spinner } from 'react-bootstrap';
import CheckoutHelper from 'src/pages/Checkout/CheckoutHelper';
import { EventBus } from 'src/helpers/new';
import * as Sentry from '@sentry/react';

export const CheckoutForm = ({ zipCode, country, orderId, packages, onBackToSummaryPage }: any) => {
    const stripe = useStripe();
    const elements = useElements();
    useEffect(() => {
        EventBus.on('verified-coupon-code', stripeSubmit);
        return () => {
            EventBus.remove('verified-coupon-code', stripeSubmit);
        };
    }, [stripe, elements]);

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        EventBus.dispatch('check-coupon-code-used');
    };

    const stripeSubmit = async (event: any) => {
        try {
            if (event) {
                if (!stripe || !elements) return;
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: `${process.env.REACT_APP_URL}/checkout/payment/confirmation/${orderId}?packages=${packages}`,
                        payment_method_data: {
                            billing_details: {
                                address: {
                                    country: country,
                                    postal_code: zipCode,
                                },
                            },
                        },
                    },
                });

                if (error) {
                    setErrorMessage(CheckoutHelper.getCardError(error));
                }
                setIsLoading(false);
            } else {
                onBackToSummaryPage();
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    const paymentElementOptions: any = {
        fields: {
            billingDetails: {
                address: {
                    country: 'never',
                    postalCode: 'never',
                },
            },
        },
    };
    return (
        <>
            {errorMessage && (
                <div>
                    <Alert variant='danger' className='error-block'>
                        <div className='triangle-icon'>
                            <i className='fa-solid fa-triangle-exclamation fa-xl'></i>
                        </div>
                        <div> {errorMessage}</div>
                    </Alert>
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <PaymentElement options={paymentElementOptions} />
                <button disabled={!stripe || isLoading}>
                    {isLoading && (
                        <>
                            <Spinner animation='border' size='sm' /> &nbsp;
                        </>
                    )}
                    Pay Now
                </button>
            </form>
        </>
    );
};
