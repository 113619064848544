import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import './PublicProfileNotifications.scss';
import { Button, Form } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { Spinner } from 'src/components/Spinner';

type optionTypes = 'email' | 'inbox';

interface CategoryPreferces {
    categoryId: string;
    silencedSubscriptions: optionTypes[];
}

interface CategoryList {
    _id: string;
    name: string;
    description: string;
    silencingNotAllowedItems: optionTypes[];
}
interface IState {
    email: string;
    token: string;
    loading: boolean;

    categories: CategoryList[];
    userPreferences: CategoryPreferces[];
    isUserExists: boolean;
    initialLoading: boolean;
}

type TProps = RouteComponentProps<{ email: string }>;

interface IProps extends TProps {}

class PublicProfileNotifications extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            email: '',
            token: '',
            initialLoading: true,
            loading: false,
            categories: [],
            userPreferences: [],
            isUserExists: false,
        };
    }

    componentDidMount = async () => {
        const query = new URLSearchParams(this.props.location.search);
        const email = query.get('email');
        const token = query.get('token');

        if (!email) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: 'Please provide an email !',
            });
        } else if (!token) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: 'Please provide an jwt token !',
            });
        } else {
            this.loadCategoriesList(decodeURIComponent(email), token);
        }
    };

    loadCategoriesList = async (email: string, token: string) => {
        this.setState({ loading: true });

        const { success, response } = await Api.call('POST', `/unsubscribe/mailingList/isUserExist?token=${token}`, {
            email: email,
        });

        if (success) {
            this.setState({ isUserExists: response.isUserExists });
            if (!response.isUserExists) {
                this.setState({ loading: false, initialLoading: false });
            } else {
                const { success: portalSuccess, response: portalResponse } = await Api.call(
                    'POST',
                    `/unsubscribe/mailingList/users/settings`,
                    {
                        email: email,
                    },
                );

                if (portalSuccess) {
                    this.setState({
                        categories: portalResponse?.docs,
                        userPreferences: portalResponse?.categoryPreferences,
                        email: email,
                        token: token,
                        loading: false,
                        initialLoading: false,
                    });
                }
            }
        } else {
            this.setState({ initialLoading: false, loading: false });
        }
    };

    handleFormChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
        categoryId: string,
        silencedSubscriptions: optionTypes,
    ) => {
        const subscribed = e.target.checked;

        this.setState((prevState) => {
            let newCategoryPreferences = [...prevState.userPreferences];

            if (!subscribed) {
                let isCategoryThere = false;

                isCategoryThere = newCategoryPreferences.some(
                    (item) => item.categoryId.toString() === categoryId.toString(),
                );

                if (!isCategoryThere) {
                    newCategoryPreferences.push({
                        categoryId: categoryId,
                        silencedSubscriptions: [silencedSubscriptions],
                    });
                }
            } else {
                newCategoryPreferences = newCategoryPreferences.filter(
                    (item) => item.categoryId.toString() !== categoryId.toString(),
                );
            }

            return {
                userPreferences: newCategoryPreferences,
            };
        });
    };

    categoriesList = () => {
        const { categories } = this.state;
        return categories?.map((category) => {
            const userList = this.state.userPreferences.find(
                (data) => data.categoryId.toString() === category._id.toString(),
            );

            const silencedSubscriptions = userList?.silencedSubscriptions ?? [];

            return (
                <div className='notification-list-container' key={category._id} id={category._id}>
                    <div className='notification-list-content-wrapper'>
                        <h4 className='heading'>{category.name}</h4>
                        <span>{category.description}</span>
                    </div>
                    <div>
                        <Form.Check
                            name={`test-id`}
                            type='checkbox'
                            checked={!silencedSubscriptions.includes('email')}
                            disabled={category.silencingNotAllowedItems.includes('email')}
                            onChange={(e) => this.handleFormChange(e, category._id, 'email')}
                        />
                    </div>
                </div>
            );
        });
    };

    savePreferences = async () => {
        const payload = {
            categoryPreferences: this.state.userPreferences,
            email: this.state.email,
            token: this.state.token,
        };
        const { success } = await Api.call('PATCH', `/update/categoryPreferences`, payload);
        if (success) {
            this.loadCategoriesList(this.state.email, this.state.token);
        }
    };

    notificationCategories = () => {
        if (this.state.loading) {
            return <Spinner />;
        } else {
            return <div className='notification-list'>{this.categoriesList()}</div>;
        }
    };

    render() {
        const { isUserExists } = this.state;

        if (this.state.initialLoading) {
            return <Spinner />;
        }

        return (
            <>
                {isUserExists ? (
                    <div className='unsubcribe-notification-container'>
                        <div className='un-wrapper'>
                            <h1 className='heading'>Communication preferences</h1>
                            <div className='sub-heading'>
                                Adjust your communication preferences so you can receive only the bits you care about.
                                you can also view your communication preferences via student profile
                            </div>
                            <h2>Communication category</h2>
                            <div className='unsubcribe-notification-main'>{this.notificationCategories()}</div>
                        </div>
                        <Button className='btn' onClick={this.savePreferences}>
                            Save Preferences
                        </Button>
                    </div>
                ) : (
                    <div className='unsubcribe-notification-container'>
                        <h1 className='heading'>The unsubscribe link is no longer valid.</h1>
                    </div>
                )}
            </>
        );
    }
}

export default withRouter(PublicProfileNotifications);
