export const STRIPE_TYPE = {
    VALIDATION_ERROR: 'validation_error',
    CARD_ERROR: 'card_error',
};

export const STRIPE_CODE = {
    INCORRECT_CVC: 'incorrect_cvc',
    BANK_ACCOUNT_DECLINED: 'bank_account_declined',
    CARD_DECLINED: 'card_declined',
};

export const STRIPE_DECLINE_CODE = {
    INSUFFICIENT_FUNDS: 'insufficient_funds',
};

export const STRIPE_MESSAGE = {
    VALIDATION_ERROR_MESSAGE:
        'Your payment could not be made. Please verify your card details are correct or try a different payment method and try again.',
    INCORRECT_CVC_MESSAGE: 'Your payment could not be made. Please verify your CVC is correct and try again.',
    BANK_ACCOUNT_DECLINED_MESSAGE:
        'Your payment could not be made as the card issuer has prevented the transaction. Please try a different payment method or if the issue persists, contact the issuing bank.',
    INSUFFICIENT_FUNDS_MESSAGE: 'Your payment could not be made due to insufficient funds.',
    CARD_DECLINED_MESSAGE:
        'Your payment could not be made. Please verify your card details are correct or if the issue persists, contact the issuing bank.',
};

export const GradingStatus = {
    REVIEW_PENDING: 'REVIEW_PENDING',
    RE_ATTEMPT_PENDING: 'RE_ATTEMPT_PENDING',
    REVIEW_COMPLETE: 'REVIEW_COMPLETE',
    ALL: 'ALL',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    PENDING: 'PENDING',
};

export const ExamStatus = {
    ALL: 'ALL',
    FAIL: 'FAIL',
    PASS: 'PASS',
};

export const ExamTrust = {
    ALL: 'ALL',
    HIGH: 'HIGH',
    LOW: 'LOW',
};

export const GradingStatusText = {
    REVIEW_PENDING: 'Awaiting review',
    RE_ATTEMPT_PENDING: 'Re Attempt Pending',
    REVIEW_COMPLETE: 'Review Complete',
};

export const DISPLAY_SIGNUP_TIMEZONE = [
    {
        _id: 'PT',
        name: 'PT (Pacific Time)',
        value: 'America/Los_Angeles',
    },
    {
        _id: 'MT',
        name: 'MT (Mountain Time)',
        value: 'America/Denver',
    },
    {
        _id: 'CT',
        name: 'CT (Central Time)',
        value: 'America/Chicago',
    },
    {
        _id: 'ET',
        name: 'ET (Eastern Time)',
        value: 'America/New_York',
    },
    {
        _id: 'LT',
        name: 'LT (Local Time)',
        value: '',
    },
];

export const ProctoringFlagWarningDuration = 60000;

export const DATEPICKERMONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const USER_ACTION_PASSWORD = {
    LOADING: 'loading',
    SUCCESS: 'success',
    FAIL: 'fail',
    ERROR: 'The password you entered do not match. Please re-enter your password.',
};

export const EXPERIMENT_STAUTS = {
    ALL: 'ALL',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};